import React from "react"

import Success from "../../images/Sucessful.svg"
import Failure from "../../images/Unsuccessful.svg"
import { navigate } from "gatsby";
import { connect } from "react-redux"
import { Order_Status, Payment_Mode, System_Type } from "../../common/constants";
import { getToken } from "../../services/auth";
import { getAccountPrefix } from "../../common/gFunctions";
import styles from "./styles.module.css"

class PaymentStatusComponent extends React.Component {
    constructor(props) {
        super(props);

        const token = getToken();

        this.state = {
            token
        }
    }

    componentDidMount() {
        if (!this.props.orderEvaluated && !this.props.orderPending) {
            navigate(getAccountPrefix(this.state.token) + '/order/product');
        }
    }

    navigateTo = (toLink) => {
        navigate(toLink);
    }

    render() {
        var {orderEvaluated, orderPending} = this.props;
        const token = this.state.token;
        var refNumText, addText2, successText = "", statusImage, buttonText, buttonLink;
        var paragraphs = [], paymentFields = [], idFields = [], activationFields = [];
        var isCloud = true;
        var isLicenseActive = false;
        var supportText = ['If you have any questions, please email us at ',<a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a>];
        if (!orderEvaluated && !orderPending) {
            return null
        } else {
            if (!orderEvaluated) {
                orderEvaluated = {
                    OrderStatusId: Order_Status.PENDING,
                    OrderCompleteDetails: orderPending
                }
            }
            const OrderCompleteDetails = orderEvaluated.OrderCompleteDetails;
            var isSuccessIcon = false;
            switch(orderEvaluated.OrderStatusId) {
                case Order_Status.PAID_AND_PROCESSED:
                    isCloud = OrderCompleteDetails.PriceDetails.NewPlan.SystemType == System_Type.CLOUD;
                    isLicenseActive = true;

                    isSuccessIcon = true;
                    successText = 'Your payment was successful. Thank you for your order!';
                    idFields = isCloud ?
                    [
                        'Email: ' + OrderCompleteDetails.EmailAddr
                    ]
                    :
                    [
                        'Customer ID: ' + OrderCompleteDetails.CustomerId,
                        'Email: ' + OrderCompleteDetails.EmailAddr
                    ];

                    //     'Your license is now active. Please login at https://cloud.mprofit.in on the web or in the MProfit mobile app with your registered email & password to use MProfit.'

                    activationFields = [
                        'Launch MProfit on your Desktop',
                        'Under the Help menu on top-right, select Activate License',
                        'Enter your Email and Customer ID (see above)',
                        'Click Activate License'
                    ];
                    refNumText = "Invoice Reference #: " + OrderCompleteDetails.InvoiceNum;
                    // buttonText = "Go to My Account";
                    // buttonLink = "/account/plan";
                    break;
                case Order_Status.PENDING:
                    isSuccessIcon = true;
                    successText = 'Thank you for your order! Your payment is pending';
                    refNumText = "Order Number: " + OrderCompleteDetails.OrderRefNum;
                    if (OrderCompleteDetails.PaymentDetails.PaymentModeCode == Payment_Mode.CHEQUE) {
                        paragraphs = [
                            ['Please deposit your cheque favouring "MProfit Software Private Limited” at your nearest State Bank of India branch.'],
                            ['Email us a scanned copy of the cheque deposit slip at ',<a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a>,' so that we can process your order and activate your license.']
                        ]
                        paymentFields = [
                            'Bank Name: State Bank of India',
                            'Account Name: MProfit Software Pvt. Ltd.',
                            'Account Number: 30574591121'
                        ]
                    } else {
                        paragraphs = [
                            ['Please email us the UTR/Ref. number for your NEFT payment at ',<a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a>,' so that we can process your order and activate your license.']
                        ]
                        paymentFields = [
                            'Company Name: MProfit Software Pvt. Ltd.',
                            'Bank Name: State Bank of India',
                            'Account Type: Current Account',
                            'Account Number: 30574591121',
                            'IFSC: SBIN0008586'
                        ]
                    }
                    break;
                default:
                case Order_Status.DECLINED:
                    statusImage = Failure;
                    successText = 'Sorry, your payment did not go through';
                    supportText = ['If you are unable to place an order on our website, please email us at ',<a href="mailto:MProfit Support<support@mprofit.in>">support@mprofit.in</a>];
                    buttonText = "Please try again";
                    buttonLink = getAccountPrefix(token) + "/order/product";
                    break;
            }
            return (
                <section className={[styles.payment_successfull_section,"plan-grey-background_","container-fluid"].join(" ")}>
                    <div className={["container", "h-100"].join(" ")}>
                        <div className={["row", "h-100", "align-items-center"].join(" ")}>
                            <div className="v_center">
                                <div className={[styles.payment_successfull].join(" ")}>
                                    <div className={["d-flex align-items-center flex-column text-center"].join(" ")}>
                                        <div>
                                            {
                                                isSuccessIcon ?
                                                <span className={styles.successCheck}><i className={"material-icons"}>check</i></span>
                                                :
                                                <img src={statusImage} alt="Status" />
                                            }
                                        </div>
                                        <h2 className={["mt-5", "pb-3"].join(" ")}>{successText}</h2>
                                        <div className={["mt-2 mb-3",styles.refNumText].join(" ")}>{refNumText}</div>
                                        <div className='mb-3'>
                                        {
                                            idFields.map((text,index) => (
                                                <div className={[styles.idText].join(" ")} key={index}>{text}</div>
                                            ))
                                        }
                                        </div>
                                        {
                                            paragraphs.map((text,index) => (
                                                <div className={["mb-3"].join(" ")} key={index}>
                                                    {
                                                        text.map((x,subindex) => (
                                                            <React.Fragment key={subindex}>
                                                                {x}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        }
                                        <div>
                                        {
                                            paymentFields.map((text,index) => (
                                                <div className={styles.paymentFields} key={index}>{text}</div>
                                            ))
                                        }
                                        {
                                            isCloud ?
                                            (
                                                isLicenseActive &&
                                                <>
                                                <div className={"mt-4"}>
                                                    Your MProfit license is now active. Please login at <a href="https://cloud.mprofit.in" target="_blank">https://cloud.mprofit.in</a> or in the MProfit mobile app to use MProfit.
                                                </div>
                                                </>
                                            )
                                            :
                                            (
                                                activationFields && activationFields.length>0 &&
                                                <>
                                                <div className={[styles.paymentFields,styles.activationTitle].join(" ")}>
                                                    {'To activate your MProfit license immediately:'}
                                                </div>
                                                <ul>
                                                {
                                                    activationFields.map((text,index) => (
                                                        <li className={[styles.activationFields].join(" ")} key={index}>
                                                            {text}
                                                        </li>
                                                    ))
                                                }
                                                </ul>
                                                </>
                                            )
                                        }
                                        </div>
                                        {
                                            buttonText &&
                                            <div className="">
                                                <button className={["btn", "btn-primary", "rounded", "mt-4"].join(" ")} onClick={() => {this.navigateTo(buttonLink)}}>{buttonText}</button>
                                            </div>
                                        }
                                        <div className={[styles.supportText].join(" ")}>
                                            {
                                                supportText.map((x,index) => (
                                                    <React.Fragment key={index}>
                                                        {x}
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                                        {/* <div className="mt-2">{addText1}</div>
                                        <div className="mt-2">{addText2}</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        orderEvaluated: state.order.OrderEvaluated,
        orderPending: state.order.OrderPending,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
    }
}

const PaymentStatus = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentStatusComponent)  

export default PaymentStatus
