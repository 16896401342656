import React from "react"
import PaymentStatus from "../../../../components/paymentStatus";
import PrivateRoute from "../../../../components/privateRoute";

class Page extends React.Component {

    componentDidMount() {
        document.body.classList.add('sel-background'); 
    }
    
    componentWillUnmount() {
        document.body.classList.remove('sel-background'); 
    }

    render() {
        return (
            <PrivateRoute path="/account/order/payment/status" component={PaymentStatus} location={this.props.location} showSingleFooter={true} environment={this.props.data.site.siteMetadata.activeEnv} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "My Order",
}

export const query = graphql`
query {
    site {
      siteMetadata {
        activeEnv
      }
    }
  }
`

export default Page